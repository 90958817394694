import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/body/itch/eucerin-itch-calming-lotion-front.webp";
import backImage from "images/body/itch/eucerin-itch-calming-lotion-back.webp";
import frontZoomImage from "images/body/itch/eucerin-itch-calming-lotion-front-zoom.webp";
import backZoomImage from "images/body/itch/eucerin-itch-calming-lotion-back-zoom.webp";

import img4 from "images/callouts/cout-footer-advanced-hydration.webp";
import img5 from "images/callouts/cout-footer-itch-aquaphor.webp";

import imgChart1 from "images/body/itch/chart-eucerin-itch-improve.webp";
import imgChart2 from "images/body/itch/chart-eucerin-itch-intensity.webp";

import "./eucerinitchrelieflotion.scss";

function IndexPage({ pageContext }) {
  useGoToAnchor();

  return (
    <Layout
      pageClass="page-body-itch page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Itch Relief Intensive Calming Lotion"
        pageDescription="Learn about Eucerin Itch Relief Intensive Calming Lotion"
      />
      <div className="gradient-bg gray-grad">
        <div className="inner-body-content gradient-bg">
          <div className="top-content-level-3">
            <section className="inner-centered-container">
              <ProductDetails
                categoryBgColor="#8399BA"
                categoryName="ITCH"
                categoryFontColor="#ffffff"
                productName="Eucerin<sup>®</sup> Itch Relief Intensive
                Calming Lotion"
                productSlider={[frontImage, backImage]}
                zoomImages={[frontZoomImage, backZoomImage]}
              >
                <div className="functions">
                  <p className="font-semi-bold lightblue-text sub-title">
                    Calms and relieves dry, itchy skin
                  </p>
                  <p>
                    <span className="font-semi-bold">
                      Eucerin Itch Relief Intensive Calming Lotion
                    </span>{" "}
                    is designed to provide immediate and long-lasting itch
                    relief. Its menthol-enriched formulation has a soothing,
                    cooling effect. The formulation is also enriched with
                    ceramide NP and helps repair the skin’s moisture barrier.
                  </p>
                  <ul className="weldon-bullets">
                    <li>
                      <span>Gentle, non-greasy formulation for daily use</span>
                    </li>
                    <li>
                      <span className="font-semi-bold">
                        Instantly relieves, calms, and soothes itchy skin
                      </span>
                    </li>
                    <li>
                      <span>Leaves skin feeling softer and smoother</span>
                    </li>
                  </ul>
                </div>
                <div className="key-ingredients">
                  <p className="font-semi-bold lightblue-text sub-title">
                    Active ingredient
                  </p>
                  <p className="font-semi-bold">
                    0.1% menthol (external analgesic)
                  </p>
                  <ul className="weldon-bullets">
                    <li>
                      <span>Soothes and relieves itch on contact</span>
                    </li>
                  </ul>
                </div>
                <div className="key-ingredients no-brd">
                  <p className="font-semi-bold lightblue-text sub-title">
                    Key ingredients
                  </p>
                  <p className="font-semi-bold">Menthoxypropanediol</p>
                  <ul className="weldon-bullets">
                    <li>
                      <span>Softens and cools skin</span>
                    </li>
                  </ul>
                  <p className="font-semi-bold">Ceramide NP</p>
                  <ul className="weldon-bullets">
                    <li>
                      <span>Helps reduce transepidermal water loss</span>
                    </li>
                  </ul>
                </div>
              </ProductDetails>
              <Row>
                <Col xs={12}>
                  <div className="features">
                    <p className="font-semi-bold lightblue-text sub-title">
                      Formulated for dry, itchy skin
                    </p>
                    <ul className="checkmarks lightblue">
                      <li>
                        <span>Fragrance-free </span>
                      </li>
                      <li>
                        <span>dye-free </span>
                      </li>
                      <li>
                        <span>paraben-free</span>
                      </li>
                      <li>
                        <span>non-comedogenic</span>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </section>
          </div>
          <div className="gradient-bg">
            <section className="inner-centered-container">
              <AccordionContent
                accordionHeading="ITCH IMPROVEMENT"
                productThemeColor="itch-lightblue"
                id="itchimprovement"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart1}
                      className="accordion-chart"
                      alt="Chart showing itch relief with 1 minute and uto 12 hours"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span>{" "}
                      Seventeen-day study duration with 2 weeks of twice-daily
                      application. Subjects had psoriasis (n=35), atopic
                      dermatitis (n=30), or xerosis (n=36). Improved itch
                      evaluated among full population (N=101).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      Itch relief within 1 minute and up to 12 hours<sup>1</sup>
                    </div>
                  </Col>
                </Row>
              </AccordionContent>

              <AccordionContent
                accordionHeading="ITCH INTENSITY"
                productThemeColor="itch-lightblue"
                id="itchintensity"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart2}
                      className="accordion-chart"
                      alt="Chart showing reduction in weekly itch frequecy with daily use"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span>{" "}
                      Seventeen-day study duration with 2 weeks of twice-daily
                      application. Subjects had psoriasis (n=35), atopic
                      dermatitis (n=30), or xerosis (n=36). Improved itch
                      evaluated among full population (N=101).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      Significant reduction in weekly itch frequency with daily
                      use<sup>1</sup>
                    </div>
                  </Col>
                </Row>
              </AccordionContent>
            </section>
          </div>

          <section className="callouts-container gradient-bg blue-grad">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img4}
                    imgAlt="Eucerin® Advanced Repair Cream"
                    caption="Offer patients advanced<br> hydration for dry skin"
                    buttonText="Eucerin<sup>&reg;</sup> Advanced Repair Cream"
                    buttonClass="long"
                    buttonUrl="/body/dryskin/eucerinadvancedrepaircream"
                    imgStyles={{ height: 158 }}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img5}
                    imgAlt="Aquaphor® Itch Relief Ointment"
                    caption="For immediate and <br /><nobr>long-lasting</nobr> itch relief
                    "
                    buttonText="Aquaphor<sup>&reg;</sup> Itch Relief Ointment"
                    buttonUrl="/body/itch/aquaphoritchreliefointment"
                    imgStyles={{ height: 165 }}
                  />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
